import { ChangeEvent, useState } from "react";
import Input from "../components/InputComponent";
import MatchTable from "../components/MatchTable";
import Santa from "../pixl_santa.png";
import { computeMatches } from "../services/matches";
import Header from "../components/Header";
import { Elf, MatchResult } from "../types/types";

function Randomizer() {
  const [elves, setElves] = useState<Elf[]>([]);
  const [name, setName] = useState<string>("");
  const [id, setId] = useState<number>(1);
  const [results, setResults] = useState<MatchResult[]>([]);
  const [exceptions, setExceptions] = useState<number[]>([]);

  const [showResults, setShowResults] = useState(false);
  const [showSanta, setShowSanta] = useState(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleAdd = (): void => {
    if (name.length > 0) {
      setElves(elves.concat({ name: name, id: id, exception: 0 }));
      setName("");
      setId(id + 1);
    }
  };

  const handleRemove = (name: Elf): void => {
    setElves(elves.filter((n) => n.id !== name.id));
  };

  const handleExceptionSelect = (name: Elf, exception: number): void => {
    if (exception === 0 && name.exception !== 0) {
      setExceptions(exceptions.filter((e) => e !== exception));
    }
    let newElves = elves;
    for (let n of newElves) {
      if (n.id === name.id) {
        n["exception"] = exception;
      }
    }
    setElves([...newElves]);
    setExceptions(exceptions.concat(exception));
  };

  const christmasTime = () => {
    setShowResults(true);
    const matches = computeMatches(elves);
    setResults(matches);
    setTimeout(() => {
      setShowSanta(false);
    }, 2000);
  };

  return (
    <>
      <Header />
      <div className="page">
        {!showResults ? (
          <>
            <div className="title">Gather Your Elves</div>
            <div className="name-input">
              <Input value={name} handleOnChange={handleChange}></Input>
              <div onClick={handleAdd} className="button button-red add-button">
                add
              </div>
            </div>
            <div className="list-wrapper">
              {elves.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="list-item">
                      <div className="name">- {item.name}</div>
                      <div
                        onClick={() => handleRemove(item)}
                        className="delete-button button"
                      >
                        x
                      </div>
                    </div>
                    <div>
                      Exception:
                      <select
                        value={item.exception || 0}
                        onChange={(e) =>
                          handleExceptionSelect(item, Number(e.target.value))
                        }
                      >
                        <option key="blank-select" value={0}>
                          -------
                        </option>
                        {elves.map((optionItem, optionIndex) => {
                          if (item.id !== optionItem.id) {
                            return (
                              <option
                                key={optionIndex + "option"}
                                value={optionItem.id}
                                disabled={exceptions.includes(optionItem.id)}
                              >
                                {optionItem.name}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                    </div>
                    <br></br>
                  </div>
                );
              })}
            </div>
            {elves.length > 2 && (
              <div
                onClick={christmasTime}
                className="random-button button button-green"
              >
                CHRISTMAS TIME!
              </div>
            )}
          </>
        ) : (
          <>
            {showSanta ? (
              <div className="rotate santa-wrapper">
                <img className="santa-img" alt="santa" src={Santa} />
              </div>
            ) : (
              <>
                <div className="title">START SHOPPING!</div>
                <MatchTable results={results} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Randomizer;
