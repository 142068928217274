import { useState, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import Input from "../components/TextInput";
import { login, loginWithGoogle } from "../api/auth";
import "./Login.css";
import googleLogo from "../assets/google.svg";
import { LoginPayload } from "../types/types";

function Login() {
  const navigate = useNavigate();
  const [data, setData] = useState<LoginPayload>({
    email: "",
    password: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    const res = await login(data.email, data.password);

    if (res.success) {
      const { email, token } = res.data;
      if (email && token) {
        localStorage.setItem("token", token);
        navigate("/");
      }
    } else {
      // TODO: handle error better
      console.log(res);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const res = await loginWithGoogle(codeResponse.code);
      if (res.success) {
        const { email, token } = res.data;
        console.log(res);
        if (email && token) {
          localStorage.setItem("token", token);
          navigate("/");
        }
      } else {
        // TODO: handle error better
        console.log(res);
      }
    },
    flow: "auth-code",
  });

  // TODO: add validation on submit

  return (
    <div className="page">
      <div className="body">
        <div className="login-title">Log into your account</div>
        <form className="login-form" onSubmit={handleLogin}>
          <Input
            inputlabel="Email"
            type="email"
            name="email"
            placeholder="Email"
            value={data.email}
            onChange={handleChange}
          />
          <Input
            inputlabel="Password"
            type="password"
            name="password"
            placeholder="Password"
            value={data.password}
            onChange={handleChange}
          />
          <button className="login-button" type="submit">
            Login
          </button>
        </form>
        <Link className="signup-link" to="/signup">
          or signup for an account
          {/* TODO: make the password validated to be the same as the backend validators for signup */}
        </Link>
        <div className="separator" />

        <button className="google" onClick={handleGoogleLogin}>
          <img src={googleLogo} alt="Google Logo" />
        </button>
      </div>
    </div>
  );
}

export default Login;
