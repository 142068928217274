import { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import Input from "../components/TextInput";
import { loginWithGoogle, signup } from "../api/auth";
import "./Signup.css";
import googleLogo from "../assets/google.svg";
import { SignupPayload } from "../types/types";

function Signup() {
  const navigate = useNavigate();
  const [data, setData] = useState<SignupPayload>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verifyPassword: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignup = async (e: FormEvent) => {
    e.preventDefault();
    const res = await signup(
      data.email,
      data.password,
      data.firstName,
      data.lastName
    );

    if (res.success) {
      const { email, token } = res.data;
      if (email && token) {
        localStorage.setItem("token", token);
        navigate("/");
      }
    } else {
      console.log(res);
      // TODO: handle error
    }
  };

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const res = await loginWithGoogle(codeResponse.code);
      if (res.success) {
        const { email, token } = res.data;
        console.log(res);
        if (email && token) {
          localStorage.setItem("token", token);
          navigate("/");
        }
      } else {
        console.log(res);
        // TODO: handle error
      }
    },
    flow: "auth-code",
  });

  // TODO: add validation on submit
  /* TODO: make the password validated to be the same as the backend validators for signup */

  return (
    <div className="page">
      <div className="body">
        <div className="signup-title">Sign up</div>
        <form className="signup-form" onSubmit={handleSignup}>
          <Input
            inputlabel="First Name"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={data.firstName}
            onChange={handleChange}
          />
          <Input
            inputlabel="Last Name"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={data.lastName}
            onChange={handleChange}
          />
          <Input
            inputlabel="Email"
            type="email"
            name="email"
            placeholder="Email"
            value={data.email}
            onChange={handleChange}
          />
          <Input
            inputlabel="Password"
            type="password"
            name="password"
            placeholder="Password"
            value={data.password}
            onChange={handleChange}
          />
          <Input
            inputlabel="Verify Password"
            type="password"
            name="verifyPassword"
            placeholder="Verify Password"
            value={data.verifyPassword}
            onChange={handleChange}
          />
          <button className="signup-button" type="submit">
            Signup
          </button>
        </form>
        <Link className="login-link" to="/login">
          already have an account?
        </Link>
        <div className="separator" />

        <button className="google" onClick={handleGoogleSignup}>
          <img src={googleLogo} alt="Google Logo" />
        </button>
      </div>
    </div>
  );
}

export default Signup;
