import { Link } from "react-router-dom";
import { useState } from "react";
import "./Header.css";

function Header() {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  //   TODO: change this to use a context or something
  const isLoggedIn = (): boolean => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogout = (): void => {
    localStorage.removeItem("token");
    setShowMenu(false);
    window.location.reload();
  };

  return (
    <>
      <div className="header">
        <Link className="button" to="/">
          Secret Santa Randomizer
        </Link>
        {/* <button className="hamburger" onClick={() => setShowMenu(!showMenu)}>
          &#9776;
        </button> */}
      </div>
      {showMenu && (
        <div className="sub-header">
          {isLoggedIn() ? (
            <>
              <Link className="sub-header-item" to="/randomizer">
                Randomize
              </Link>
              <a className="sub-header-item" onClick={handleLogout}>
                Logout
              </a>
            </>
          ) : (
            <>
              <Link className="sub-header-item" to="/login">
                Login
              </Link>
              <Link className="sub-header-item" to="/signup">
                Sign Up
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
