import { User } from "../types/models/users.types";
import { Response, SignupResponse, LoginResponse } from "../types/types";

const baseUrl = process.env.REACT_APP_API_URL;

export const login = async (
  email: string,
  password: string
): Promise<Response<LoginResponse>> => {
  const res = await fetch(`${baseUrl}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        console.log(response);
      }
      const data: LoginResponse = await response.json();
      return { data: data, success: response.ok };
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      return { data: {} as LoginResponse, success: false, error: error };
    });
  return res;
};

export const signup = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<Response<SignupResponse>> => {
  const res = await fetch(`${baseUrl}/users/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        console.log(response);
      }
      const data: SignupResponse = await response.json();
      return { data: data, success: response.ok };
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      return { data: {} as SignupResponse, success: false, error: error };
    });
  return res;
};

export const loginWithGoogle = async (
  code: string
): Promise<Response<LoginResponse>> => {
  const res = await fetch(`${baseUrl}/users/login/google`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth_code: code,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        console.log(response);
      }
      const data: LoginResponse = await response.json();
      return { data: data, success: response.ok };
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      return { data: {} as LoginResponse, success: false, error: error };
    });
  return res;
};

export const me = async (): Promise<Response<User>> => {
  const res = await fetch(`${baseUrl}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        console.log(response);
      }
      const data: User = await response.json();
      return { data: data, success: response.ok };
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      return { data: {} as User, success: false, error: error };
    });
  return res;
};
