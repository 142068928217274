import { ComponentProps } from "react";

interface InputProps extends ComponentProps<"input"> {
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
}

function Input(props: InputProps) {
  const { handleOnChange, value, inputType = "text" } = props;

  return (
    <div className="input-wrapper">
      <input
        className="text-input"
        onChange={handleOnChange}
        type={inputType}
        value={value}
      />
    </div>
  );
}

export default Input;
