import { ComponentProps } from "react";
import "./TextInput.css";

interface InputProps extends ComponentProps<"input"> {
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
  inputlabel: string;
}

function Input(props: InputProps) {
  return (
    <div className="text-input-wrapper">
      <label htmlFor={props.name} className="label">
        {props.inputlabel}
      </label>
      <input className="text-input" {...props} />
    </div>
  );
}

export default Input;
