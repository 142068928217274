import { Elf, MatchResult } from "../types/types";

export const computeMatches = (names: Elf[]): MatchResult[] => {
  let warning: boolean = false;
  let hat: Elf[] = names;
  let results: MatchResult[] = [];
  for (let i = 0; i < names.length; i++) {
    let currentElf = names[i];
    let hatWithoutElf = hat.filter((name) => name.id !== currentElf.id);
    if (currentElf.exception) {
      hatWithoutElf = hatWithoutElf.filter(
        (name) => name.id !== currentElf.exception
      );
    }
    let match = hatWithoutElf[Math.floor(Math.random() * hatWithoutElf.length)];
    if (match) {
      hat = hat.filter((name) => name.id !== match.id);
      results.push({ elf: currentElf, match: match });
    } else {
      warning = true;
    }
  }
  if (warning) {
    alert("you may have had more exceptions than mathmatically possible!");
  }
  return results;
};
