import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Randomizer from "./pages/Randomizer";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import "./App.css";

const isLoggedIn = (): boolean => {
  const token: string | null = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/randomizer",
    element: <Randomizer />,
  },
  {
    path: "/login",
    element: isLoggedIn() ? <Home /> : <Login />,
  },
  {
    path: "/signup",
    element: isLoggedIn() ? <Home /> : <Signup />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
